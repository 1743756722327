import React, { useState, useEffect, useContext, useRef } from "react";
import DomainContext from "Context/DomainContext";
import "./styles.scss";
import Breadcrumb from "Components/Breadcrumb";
import FilterSidebar from "Components/Product/ProductListing/SubCategories/FilterSidebar";
import GridList from "Components/Product/ProductListing/SubCategories/GridList";
import AdditionalData from "Components/Product/ProductListing/SubCategories/AdditionalData";
import { useLocation, useSearchParams } from "react-router-dom";
import { APIQueryGet, APIQueryPost } from "APIMethods/API";
import Seo from "Components/Seo/Seo";
import NoRoute from "Components/NoRoute/NoRoute";
import TagManager from 'react-gtm-module';
import { useSelector } from 'react-redux';

const SubCategories = () => {
  const localData = localStorage.getItem('persist:root');
  const x = localData && JSON.parse(localData);
  const customerId = JSON.parse(x?.customerDetails)?.id;
  const { storeId, defaultURL } = useContext(DomainContext);
  const [openModel, setOpenModel] = useState(false);
  const APIRef = useRef(false);
  const [searchParams] = useSearchParams();
  const [loading, setProductpageLoading] = useState(true);
  const [thirdLevelData, setThirdLevelData] = useState([]);
  const [plpData, setPlpData] = useState({});
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(0);
  const urlFiter = location?.search?.slice(1)?.split("&")?.filter((item) => item?.split("=")?.[0] !== "page_size" && item?.split("=")?.[0] !== "product_list_order");
  let filredData = [];
  const getFooterData = useSelector(state => state?.getHeaderFooterData?.data?.footer?.[0]);
  const allTypesFilter = urlFiter?.forEach((item) => {
    let data = item?.split('=');
    let keyName = data?.[0];
    let value = data?.[1]?.split('%');
    if (urlFiter?.[0] !== '') {
      filredData?.push({ [keyName]: value });
    }
  });
  const sortingData = searchParams?.get('product_list_order');
  function createUrlWithParams({  storeId, filredData, sortingData, currentPage, customerId }) {
    const baseUrl = `${defaultURL}/plp/getDetails`;
  
   
  let queryParams = `storeId=${storeId}&rootCatId=2&categoryUrl=${location?.pathname?.slice(1)}&searchString=&sortingData[value]=${sortingData?.value || ''}&sortingData[sortBy]=${sortingData?.sortBy || ''}&pageSize=${(currentPage + 1) > 1 ? 14 : 14}&pageOffset=${currentPage + 1}&customerId=${customerId ? customerId : 0}`;

  if (filredData?.length) {
    filredData.forEach((filter, index) => {
      Object.keys(filter).forEach((key) => {
        filter[key].forEach((value, i) => {
          queryParams += `&filterData[${index}][${key}][${i}]=${value}`;
        });
      });
    });
  }

  
    return `${baseUrl}?${queryParams}`;
  }

  const urlParams = createUrlWithParams({
    storeId: storeId,
    filredData: filredData?.length ? filredData : [], // example filtered data
    currentPage: 0,
    sortingData: {
      value: sortingData ? sortingData : '',
      sortBy: ''
    },
    pageOffset: currentPage + 1,
    customerId: customerId ? customerId : 0,
 
  });
  const options = {
    isLoader: true,
    loaderAction: (bool) => setProductpageLoading(bool),
    axiosData: {
      url: urlParams,
     
    },
    setGetResponseData: (res) => {
      const data = res?.data[0];
      if (plpData?.products?.length && (currentPage + 1) > 1) {
        setPlpData((prevState) => ({
          ...prevState,
          products: data?.products
        }));
      } else {
        setPlpData(data);
        if (data?.categoryFilter && data?.categoryFilter?.length)
          setThirdLevelData([...data?.categoryFilter])
      }
    }
  };
  const searchResultOptions = {
    isLoader: true,
    loaderAction: (bool) => {
      setProductpageLoading(bool);
      if (location?.pathname?.slice(16)?.split('/')?.[0]?.replace(/%20/g, " ") !== plpData?.searchTerms) {
        setCurrentPage(0);
      }
    },
    axiosData: {
      url: `${defaultURL}/searchresult/getproducts`,
      paramsData: {
        storeId: storeId,
        keyword: location?.pathname?.slice(16)?.split('/')?.[0] ? location?.pathname?.slice(16)?.split('/')?.[0]?.replace(/%20/g, " ") : '',
        filterData: filredData?.length ? filredData : [],
        sortingData: {
          value: sortingData ? sortingData : ''
        },
        productType: "",
        pageSize: (currentPage + 1) > 1 ? 15 : 14,
        pageOffset: location?.pathname?.slice(16)?.split('/')?.[0]?.replace(/%20/g, " ") === plpData?.searchTerms ? currentPage + 1 : 1,
      }
    },
    setGetResponseData: (res) => {
      const data = res?.data[0];
      if (plpData?.products?.length && (currentPage + 1) > 1) {
        setPlpData((prevState) => ({
          ...prevState,
          products: data?.products
        }));
      } else {
        setPlpData(data);
        if (data?.categoryFilter && data?.categoryFilter?.length)
          setThirdLevelData([...data?.categoryFilter])
      }
    }
  };

  // Decode the URL
  const encodedUrl = location?.pathname?.slice(16)?.split('/')?.[0];
  const decodedUrl = decodeURIComponent(encodedUrl);
  const searchBreadCrumb = [
    {
      categoryName: `We hebben voor u gezocht op '${decodedUrl}':`,
      urlKey: ""
    }
  ]

  const emptyBreadCrumb = [
    {
      categoryName: "",
      urlKey: ""
    }
  ]
  useEffect(() => {
    if (!APIRef.current) {
      if (location?.state?.isSearchResult || location?.pathname?.includes("/search/")) {
        APIQueryPost(searchResultOptions);
      } else {
        APIQueryGet(options);
      }
      APIRef.current = true;
      setTimeout(() => APIRef.current = false, 100);
    }
  }, [location, currentPage]);
  const productListingPage2Gtm = () => {
    let productListingPage2GtmData = {
      dataLayer: {
        event: 'Product_Listing_Page2',
      }
    };
    TagManager.dataLayer(productListingPage2GtmData);
  };
  const StructuredData = () => {
    useEffect(() => {
      const script = document.createElement('script');
      script.type = 'application/ld+json';
      script.text = JSON.stringify({
        "@context": "http://schema.org",
        "@type": "WebSite",
        "url": "https://www.promofit.nl/",
        "name": "Promofit.nl",
        "potentialAction": {
          "@type": "SearchAction",
          "target": "https://www.promofit.nl/catalogsearch/result/?q={search_term_string}",
          "query-input": "required name=search_term_string"
        }
      });

      document.head.appendChild(script);

      // Cleanup function to remove the script when the component is unmounted
      return () => {
        document.head.removeChild(script);
      };
    }, []);

    return null; // This component doesn't render anything visible
  };
  const OrganizationSchema = () => {
    const organizationSchemaRef = useRef(null);

    useEffect(() => {
      if (!organizationSchemaRef.current) {
        organizationSchemaRef.current = document.createElement('script');
        organizationSchemaRef.current.type = 'application/ld+json';
        document.head.appendChild(organizationSchemaRef.current);
      }

      const scriptContent = {
        "@context": "http://schema.org",
        "@type": "Organization",
        "url": "https://www.promofit.nl/",
        "name": "Promofit",
        "logo": "https://www.promofit.nl/media/logo/stores/1/logo-promofit-M2.png",
        "description": "Promofit is sinds 2006 de toonaangevende leverancier voor relatiegeschenken, promotionele artikelen, give-aways en beursmaterialen. Wij bedrukken bijna alles!...",
        "contactPoint": [
          {
            "@type": "ContactPoint",
            "telephone": "+3176 50 182 25",
            "contactType": "sales"
          },
          {
            "@type": "ContactPoint",
            "telephone": "+3176 50 182 25",
            "contactType": "customer service"
          }
        ],
        "address": {
          "addressCountry": "Nederland",
          "addressRegion": "Noord Brabant",
          "addressLocality": "Etten Leur"
        },
        "sameAs": [
          "https://nl-nl.facebook.com/promofit",
          "https://twitter.com/Promofit_NL",
          "https://www.instagram.com/promofit.nl/",
          "https://www.youtube.com/channel/UCH_Nu6o9B_h0pEvz9Xodp9Q",
          "https://www.linkedin.com/company/promofit/",
          "https://www.pinterest.com/promofit/"
        ]
      };

      organizationSchemaRef.current.innerHTML = JSON.stringify(scriptContent);

      return () => {
        // Cleanup function to remove the script element when the component is unmounted
        if (organizationSchemaRef.current) {
          document.head.removeChild(organizationSchemaRef.current);
          organizationSchemaRef.current = null;
        }
      };
    }, []);

    return null;
  };


  const ShopReviewSchema = () => {
    const shopReviewRef = useRef(null);

    useEffect(() => {
      // Create the script element
      const script = document.createElement('script');
      script.type = 'application/ld+json';
      script.id = 'shopReview';

      const scriptContent = {
        "@context": "http://schema.org",
        "@type": "Product",
        "url": "",
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": getFooterData?.reviews?.kiyohReviews[0]?.avg_rating,
          "reviewCount": getFooterData?.reviews?.kiyohReviews[0]?.total_reviews
        },
        "name": storeId == 1 ? "Promofit" : storeId == 2 ? "Expofit" : ""
      };

      script.textContent = JSON.stringify(scriptContent);

      document.head.appendChild(script);

      shopReviewRef.current = script;

      return () => {
        if (shopReviewRef.current) {
          document.head.removeChild(shopReviewRef.current);
        }
      };
    }, []);

    return null;
  };

  // scroll top
  useEffect(() => {
    window.scrollTo(0, 0);
    productListingPage2Gtm()
  }, [location]);

  const BreadcrumbSchema = () => {
    const breadcrumbRef = useRef(null);

    useEffect(() => {
      if (!breadcrumbRef.current) {
        breadcrumbRef.current = document.createElement('script');
        breadcrumbRef.current.type = 'application/ld+json';
        document.head.appendChild(breadcrumbRef.current);
      }

      const scriptContent = {
        "@context": "http://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": plpData?.breadCrums?.map((item, index) => ({
          "@type": "ListItem",
          position: (index + 1).toString(),
          item: {
            id: item?.catId,
            url: item?.urlKey,
            name: item?.categoryName
          }
        })),
      };

      breadcrumbRef.current.innerHTML = JSON.stringify(scriptContent);

      return () => {
        // Cleanup function to remove the script element when the component is unmounted
        if (breadcrumbRef.current) {
          document.head.removeChild(breadcrumbRef.current);
          breadcrumbRef.current = null;
        }
      };
    }, []);

    return null;
  };


  return (
    <>
      {
        !loading && plpData?.products?.[0]?.code === 400 ?
          <NoRoute />
          : <>
            <Seo
              metaTitle={plpData?.seo?.metaTitle}
              metaDescription={plpData?.seo?.metaDescription}
              metaKeywords={plpData?.seo?.metaKeywords}
              ogWebSite="Product listing"
            />
            <BreadcrumbSchema />
            <OrganizationSchema />
            <ShopReviewSchema />
            <StructuredData />
            {
              !loading && plpData?.products?.[0]?.code === 400 ?
                <Breadcrumb type="plpParentCategories" data={emptyBreadCrumb} loading={loading} />
                : location?.state?.isSearchResult || location?.pathname?.includes("/search/") ?
                  <Breadcrumb type="plpParentCategories" data={searchBreadCrumb} loading={plpData?.products?.length ? false : loading} />
                  :
                  <Breadcrumb type="plpParentCategories" data={plpData?.breadCrums} loading={plpData?.products?.length ? false : loading} />
            }
            {
              (location?.pathname?.includes("/search/") && plpData?.searchTerms && !loading) && plpData?.products?.[0]?.code === 200 ?
                <div className="search__nodata msg__block flex center middle py-8 px-4 mt-10">
                  <h4 className="fw-500 px-6 py-4 r-2 overflow">{plpData?.products?.[0]?.message}</h4>
                </div>
                :
                <>
                  <div className="plp2 container pt-2 xl-pb-13 px-4">
                    {
                      location?.pathname?.includes("/search/") ?
                        <h2 className="fs-20 fw-700 line-7 pb-6">We hebben voor u gezocht op '{decodedUrl}':</h2>
                        : <></>
                    }
                    <div className="productlisting__container flex gap-x-18">
                      <div className="productlisting__sidebar flex-0">
                        <FilterSidebar openModel={openModel} setOpenModel={setOpenModel} plpDatas={plpData} loading={loading} currentPage={currentPage} setCurrentPage={setCurrentPage} setThirdLevelData={setThirdLevelData} />
                      </div>
                      <div className="flex-1">
                        <GridList setOpenModel={setOpenModel} plpData={plpData} loading={loading} currentPage={currentPage} setCurrentPage={setCurrentPage} sortingData={sortingData} thirdLevelData={thirdLevelData} />
                      </div>
                    </div>
                  </div>
                  {
                    !loading && plpData?.products?.[0]?.code === 400 ?
                      <></>
                      :
                      <div className="addtional__data__container">
                        <AdditionalData plpData={plpData} loading={plpData?.products?.length ? false : loading} />
                      </div>
                  }
                </>
            }


          </>
      }
    </>
  );

};
export default SubCategories;










