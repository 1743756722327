import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import { persister } from "./Store/store";
import { PersistGate } from "redux-persist/integration/react";
import { useSelector } from "react-redux";
import CircularProgress from '@mui/material/CircularProgress';
import Header from "Components/Header";
import DomainContext from "Context/DomainContext";
import AppRoutes from "./AppRoutes";
import { getStoreData, initDB } from './db';

// Import css files
import "./App.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import RenderContext from 'Context/RenderContext';
import VisibleWarp from 'Context/VisibleWrapper';
import axios from 'axios';

const AppScripts = React.lazy(() => import('./appScripts'));
const Footer = React.lazy(() => import('./Components/Footer'));

const App = () => {
  const loader = useSelector((state) => state?.loader);
  const { baseURL,defaultURL } = useContext(DomainContext);
  const { loadIfUser, loadPreRender } = useContext(RenderContext);
  const LoaderProgress = () => {
    return (
      <div style={{ zIndex: 99, background: "rgba(0,0,0,0.08)" }} className="fixed top-0 left-0 w-1/1 h-1/1 flex center middle">
        <CircularProgress />
      </div>
    )
  };
  const handleInitDB = async () => {
    await initDB();
  };

  useEffect(() => {
    handleInitDB();
    getStoreData("recentUser");
  }, []);

  useEffect(() => {
    caches.keys().then((names) => {
      names?.forEach((name) => {
        caches.delete(name);
      });
    });
  }, []);
  
  useEffect(() => {
    const fetchDataEveryTwoMinutes = async () => {
      const localCache = localStorage.getItem("localCache");
      if(defaultURL){
        try {
          let response = await axios.get(`${defaultURL}/home/getCache`);
          if (response?.data?.[0]?.cache === localCache) {
            return;
          } else if(localCache){
            localStorage.setItem("localCache", response?.data?.[0]?.cache);
            caches.keys().then((names) => {
              names?.forEach((name) => {
                caches.delete(name);
              });
            });
            window.location.reload();
          }else{
            localStorage.setItem("localCache", response?.data?.[0]?.cache);
          }
        } catch (error) {
          console.error("Error fetching cache data:", error);
        }
      }
    };

    const intervalId = setInterval(fetchDataEveryTwoMinutes, 2 * 60 * 1000);

    // Clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, [defaultURL]);

  return (
    <div className="App">
      <PersistGate loading={null} persistor={persister}>
        {baseURL ? (
          <Router>
            <Header />
            <AppRoutes />
            {/* {(loadIfUser || loadPreRender) && <Footer />} */}
            <VisibleWarp>
            <Footer />
            </VisibleWarp>
          </Router>
        ) : <></>}
      </PersistGate>
      {/* loader */}
      {(loader || !baseURL) ? <LoaderProgress /> : (loadIfUser || loadPreRender) ? <React.Suspense fallback={null} > <AppScripts /> </React.Suspense> : null}
    </div>
  );
};

export default App;